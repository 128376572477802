(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('ldtpApp')
        .constant('VERSION', "4.0.60")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_TIMESTAMP', 1732728291309)
;
})();
